import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GooglePayButton from '@google-pay/button-react';
import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import ProfileOptionsAPI from '../../API/profileOptionsAPI';
import { useDispatch, useSelector } from 'react-redux';
import { resetCustomer, selectCustomer } from '../../features/customer/customerSlice';
import CustomerObj from '../../features/customer/customerObj';
import { clearCart, selectCartCoupon, selectCartItems, selectOrderInProgress, setOrderInProgress } from '../../features/cart/cartSlice';
import CouponObj from '../../features/coupons/couponObj';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import { selectProducts } from '../../features/products/productsSlice';
import CartObj from '../../features/cart/cartObj';
import { FormValues } from '../checkout-form/interfaces';
import { useAcceptJs } from 'react-acceptjs';
import OrderObj from '../../features/orders/orderObj';
import CheckoutUtils from '../../features/checkout/checkoutUtils';
import OrdersAPI from '../../API/ordersAPI';
import { selectPendingOrder, setPendingOrder } from '../../features/checkout/checkoutSlice';
import { setPointsCoupon } from '../../features/coupons/couponsSlice';
import { resetReferrals, selectAffiliate, selectVisit } from '../../features/referrals/referralsSlice';
import { addOrder } from '../../features/orders/ordersSlice';
import { useNavigate } from 'react-router-dom';
import { selectToken } from '../../features/user/userSlice';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import AuthNetAPI from '../../API/authNetAPI';
import { selectGeneralOptions, selectLifeTimeAffiliate, selectVisitorID } from '../../features/mobile/mobileSlice';

interface Props {
    values: FormValues;
}

type Environment = 'TEST' | 'PRODUCTION';

const authNetEnv = process.env.REACT_APP_AUTHORIZE_NET_ENV === 'PRODUCTION' ?
    'PRODUCTION' : 'SANDBOX';
const authNetData = {
    apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_API_LOGIN_ID || '',
    clientKey: process.env.REACT_APP_AUTHORIZE_NET_PUBLIC_CLIENT_KEY || ''
}

export default function PaymentOptions({ values }: Props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let token = useSelector(selectToken);
    const affiliateData = useSelector(selectAffiliate);
    const referralVisitData = useSelector(selectVisit);
    const affiliateVisitorID = useSelector(selectVisitorID);
    const pendingOrderData = useSelector(selectPendingOrder);
    const lifeTimeAffiliate = useSelector(selectLifeTimeAffiliate);
    const [canMakePayments, setCanMakePayments] = useState(false);
    const [paymentOption, setPaymentOption] = useState('');
    const [paymentMode, setPaymentMode] = useState<Environment>('TEST');
    const [gatewayMerchantId, setGateWayMerchatID] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const authorizeNet = useAcceptJs({
        environment: authNetEnv,
        authData: authNetData
    })
    const custData = useSelector(selectCustomer);
    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);
    const cartCouponData = useSelector(selectCartCoupon);
    const cartCoupon = useMemo(() => {
        return CouponObj.createCoupon(cartCouponData);
    }, [cartCouponData]);
    const cartItems = useSelector(selectCartItems);
    const cart = useMemo(() => {
        return new CartObj(cartItems);
    }, [cartItems]);
    const products = useSelector(selectProducts);
    const orderInProgress = useSelector(selectOrderInProgress);
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const shippingMethods = useSelector(selectShippingMethods);
    const shippingMethodCost = ShippingMethodObj.getShippingMethodCost(
        values?.shipping_method, shippingMethods);
    const cartTotal = cart.getTotal(products, shippingMethodCost, cartCoupon, customer) as number;
    const publishKey = getGeneralOptions?.payment_mode === 'live' ?
        process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY :
        process.env.REACT_APP_STAGE_STRIPE_PUBLISHABLE_KEY;
    const maxUsablePoints = customer.getMaxUsablePoints(
        cart.getSubtotal(null, null, null) as number
    );

    useEffect(() => {
        async function fetchData() {
            try {
                const getGeneralOptions = await ProfileOptionsAPI.getGeneralOptions();
                if (getGeneralOptions) {
                    const { payment_option, payment_mode } = getGeneralOptions;

                    if (payment_option === 'stripe') {
                        setPaymentOption('stripe');
                        setGateWayMerchatID(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
                    } else {
                        setPaymentOption('authorizenet');
                        setGateWayMerchatID(`${process.env.REACT_APP_AUTHORIZE_MERCHANTID}`);
                    }

                    setPaymentMode(payment_mode === 'test' ? 'TEST' : 'PRODUCTION');
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        // Check if Apple Pay is available on the current device
        if ((window as any).ApplePaySession) {
            setCanMakePayments(true);
        }
    }, []);

    const addCouponToOrder = (order: OrderObj, shippingCost: number) => {
        if (!cartCoupon) return;

        if (cartCoupon.isPointsCoupon(customer)) {
            order.addPointsToMeta(
                maxUsablePoints,
                customer.data.mightypoints_discount_code
            );
        } else if (cartCoupon.data.discount_type === 'smart_coupon') {
            order.addSmartCouponToMeta(
                cartCoupon,
                cart.getTotal(products, shippingCost, null, customer) as number);
        }
        order.data.coupon_lines = [{ "code": cartCoupon.data.code }]

    }

    const createOrderFromFormValues = (customerId: number, PaymentMethodTitle: any) => {
        let deliveryDate = undefined;

        if (!values.shipping_method) {
            setErrorMessage('Please verify shipping method.');
            return null
        }

        const shippingMethod = ShippingMethodObj.getById(
            shippingMethods,
            values.shipping_method
        );

        if (!shippingMethod) {
            setErrorMessage('Shipping method not found.');
            return null
        }

        if (!shippingMethod.isEmailDelivery()) {
            if (!values.delivery_date) {
                setErrorMessage('Delivery date not found.');
                return null
            }

            deliveryDate = values.delivery_date.getFullYear().toString() + '-' +
                ('0' + (values.delivery_date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + values.delivery_date.getDate()).slice(-2);
        }

        let data = {
            "customer_id": customerId,
            "billing": {
                "first_name": values.billing_first_name,
                "last_name": values.billing_last_name,
                "address_1": values.billing_address_1,
                "address_2": values.billing_address_2,
                "city": values.billing_city,
                "country": "US",
                "state": values.billing_state,
                "postcode": values.billing_postcode,
                "email": values.email,
                "phone": values.phone
            },
            "shipping": {},
            "line_items": cart.getLineItems(products),
            "coupon_lines": [],
            "shipping_lines": [{
                "method_id": 'flat_rate',
                "method_title": shippingMethod.data.title,
                "total": shippingMethod.data.cost
            }],
            "customer_note": values.delivery_notes,
            "payment_method": `${paymentOption}-${PaymentMethodTitle === "Google Pay" ? "gpay" : "applepay"}`,
            "payment_method_title": `${PaymentMethodTitle} (${paymentOption})`,
            "meta_data": [{
                "key": "Chosen Pickup Location",
                "value": "I'm having my meals delivered"
            }, {
                'key': '_mm_rest_order',
                'value': "true"
            }]
        }

        if (deliveryDate) {
            data.meta_data.push({
                "key": "_delivery_date",
                "value": deliveryDate
            });
        }

        if (!customer.hasMembership()) {
            data.meta_data.push({
                "key": "_wc_points",
                "value": cart.getPoints(products).toString()
            });
        }

        if (values.use_shipping) {
            data.shipping = {
                "first_name": values.shipping_first_name,
                "last_name": values.shipping_last_name,
                "address_1": values.shipping_address_1,
                "address_2": values.shipping_address_2,
                "city": values.shipping_city,
                "country": "US",
                "state": values.shipping_state,
                "postcode": values.shipping_postcode
            }
        } else {
            data.shipping = {
                "first_name": values.billing_first_name,
                "last_name": values.billing_last_name,
                "address_1": values.billing_address_1,
                "address_2": values.billing_address_2,
                "city": values.billing_city,
                "country": "US",
                "state": values.billing_state,
                "postcode": values.billing_postcode
            }
        }

        let order = new OrderObj(data);

        if (cartCoupon) {
            addCouponToOrder(order, parseFloat(shippingMethod.data.cost));
        }

        if (cart.hasProductWithCategory('gift-card', products)) {
            if (values.send_gift_card) {
                order.addGiftCardMeta(values.gift_card_email,
                    values.gift_card_msg, true);
            } else {
                order.addGiftCardMeta(values.email);
            }
        }
        return order;
    }

    const handleApplePayPayment = async () => {
        if ((window as any).ApplePaySession) {
            const paymentRequest = {
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    label: 'MightyMeals',
                    amount: '1.00',
                    type: 'final',
                },
                requiredBillingContactFields: ['postalAddress', 'name'],
                requiredShippingContactFields: ['postalAddress', 'name', 'phone', 'email'],
                supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
                merchantCapabilities: ['supports3DS'],
            };

            try {
                const session = new (window as any).ApplePaySession(1, paymentRequest);

                const validationURLStaging = "https://apple-pay-gateway-cert.apple.com/paymentservices/startSession";
                session.onvalidatemerchant = (event: { validationURL: any; }) => {
                    //const validationURL = event.validationURL;
                    // Send validationURL to the backend for validation with Authorize.Net
                    alert(`Apple Pay validationURL: ${validationURLStaging}`);
                    AuthNetAPI.applepayvalidatemerchant(validationURLStaging)
                        .then((response) => response.json())
                        .then((merchantSession) => {
                            alert(`Apple Pay onvalidatemerchant: ${merchantSession}`);
                            session.completeMerchantValidation(merchantSession);
                        })
                        .catch((error) => {
                            alert(`Merchant validation failed: ${error}`);
                            console.error('Merchant validation failed:', error);
                        });
                };

                session.onpaymentauthorized = (event: { payment: any; }) => {
                    const payment = event.payment;
                    alert(`Apple Pay onpaymentauthorized: ${payment}`);
                    // Process payment on backend using opaqueData
                    // AuthNetAPI.captureApplePay(payment)
                    //     .then((response: { success: any; }) => {
                    //         if (response.success) {
                    //             session.completePayment((window as any).ApplePaySession.STATUS_SUCCESS);
                    //         } else {
                    //             session.completePayment((window as any).ApplePaySession.STATUS_FAILURE);
                    //         }
                    //     })
                    //     .catch((error: any) => {
                    //         session.completePayment((window as any).ApplePaySession.STATUS_FAILURE);
                    //         console.error('Payment failed:', error);
                    //     });
                };

                session.begin();
            } catch (error) {
                console.error('Apple Pay session failed:', error);
            }
        }
    };

    const handlePaymentProcess = (PaymentMethodTitle: any) => {
        let checkoutCtxt: Record<string, any> = {
            order: new OrderObj({}),
            token: token,
            customerId: 'id' in customer.data ? customer.data.id : null,
            authNonce: '',
            affiliateData: affiliateData,
            referralVisitData: referralVisitData
        }

        setErrorMessage('');
        dispatch(setOrderInProgress(true));

        Promise.resolve().then(() => {
            // If we need to create an account, do so, and authenticate
            if (!token && values.password) {
                return CheckoutUtils.registerAndAuthenticate(values.email, values.password,
                    dispatch, checkoutCtxt);
            }
        }).then(() => {
            // If using authorize.net, get Auth Nonce
            if (process.env.REACT_APP_CREDIT_CARD_PROCESSOR === 'authorize.net') {
                return CheckoutUtils.getAuthNetNonce(values.cardNumber, values.cardCvc,
                    values.cardExpiry, authorizeNet, checkoutCtxt);
            }
        }).then(() => {
            let order: OrderObj | null;
            order = createOrderFromFormValues(checkoutCtxt.customerId, PaymentMethodTitle);

            if (!order) throw new Error("An error has occurred. Please check details and try again later.");

            if (Object.keys(pendingOrderData).length > 0) {
                // Make deep copy of pending order
                let updatedOrder = new OrderObj(JSON.parse(JSON.stringify(pendingOrderData)));
                if (updatedOrder.ageInSeconds() < 3600) {
                    updatedOrder.updateData(order.data);
                    if (cartCoupon && values.shipping_method) {
                        const shippingMethod = ShippingMethodObj.getById(
                            shippingMethods,
                            values.shipping_method
                        );

                        if (shippingMethod) {
                            addCouponToOrder(updatedOrder, parseFloat(shippingMethod.data.cost));
                        }
                    } else {
                        updatedOrder.removePointsFromMeta();
                        updatedOrder.removeSmartCouponFromMeta();
                    }

                    if (cart.hasProductWithCategory('gift-card', products)) {
                        if (values.send_gift_card) {
                            updatedOrder.addGiftCardMeta(values.gift_card_email,
                                values.gift_card_msg, true);
                        } else {
                            updatedOrder.addGiftCardMeta(values.email);
                        }
                    } else {
                        updatedOrder.removeGiftCardMeta();
                    }

                    return OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id, updatedOrder.data);
                } else {
                    // Pending order is over an hour old. Mark it canceled
                    try {
                        OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id,
                            { status: 'canceled' });
                    } catch (e) {
                        console.error("Error while canceling order:", e);
                    }
                    dispatch(setPendingOrder({}));
                }
            }
            console.log("before create", order.data);
            return OrdersAPI.createOrder(token, order.data);
        }).then((orderData) => {
            console.log("after create", orderData);
            if ('code' in orderData && 'message' in orderData) {
                throw new Error(orderData.message);
            }
            if (!('id' in orderData)) {
                throw new Error('An error has occurred. Please try again later.');
            }

            dispatch(setPendingOrder(orderData));
            // Deep copy order and add to context
            checkoutCtxt.order = new OrderObj(JSON.parse(JSON.stringify(orderData)));
        }).then(() => {
            // Order is complete. Track purchase with FB Pixel and GA4
            ReactPixel.track(
                'Purchase',
                {
                    currency: 'USD',
                    num_items: cart.getItemCount(),
                    content_type: 'product',
                    content_ids: cart.getProductIDs(),
                    content_name: cart.getProductNames(products),
                    value: checkoutCtxt.order.data.total
                }
            );
            ReactGA.event(
                'purchase',
                {
                    currency: 'USD',
                    transaction_id: checkoutCtxt.order.data.id,
                    value: checkoutCtxt.order.data.total,
                    items: cart.getItemsForGA(products)
                }
            );
            CheckoutUtils.maybePostReferral(customer, checkoutCtxt, lifeTimeAffiliate, affiliateVisitorID);
            dispatch(setPendingOrder({}));
            dispatch(clearCart(checkoutCtxt.token));
            dispatch(resetCustomer());
            dispatch(setPointsCoupon(null));
            dispatch(resetReferrals());

            let orderData: Record<string, any> = { status: 'processing' };
            if ('authNetTransId' in checkoutCtxt) {
                // Make deep copy of the meta_data
                orderData.meta_data = JSON.parse(JSON.stringify(checkoutCtxt.order.data.meta_data));
                orderData.meta_data.push({
                    "key": "_authorize_net_trans_id",
                    "value": checkoutCtxt.authNetTransId
                });
            }
            return OrdersAPI.updateOrder(checkoutCtxt.token, checkoutCtxt.order.data.id, orderData);
        }).then((orderData) => {
            // Add the new order to orders slice
            if (orderData) {
                checkoutCtxt.order = new OrderObj(orderData);
                dispatch(addOrder(orderData));
            }

            navigate('/checkout/order-received/' + checkoutCtxt.order.data.id);
        }).catch((e) => {
            setErrorMessage(e.message);
        }).finally(() => {
            dispatch(setOrderInProgress(false));
        });
    }

    const handlePaymentAuthorized = async (paymentData: any) => {
        console.log('Payment data:', paymentData);
        handlePaymentProcess("Google Pay");
        return { transactionState: 'SUCCESS' } as google.payments.api.PaymentAuthorizationResult;
    };

    const handleLoadPaymentData = (paymentRequest: any) => {
        console.log('Load payment data:', paymentRequest);
    };

    const handleError = (error: any) => {
        console.log(error, "error")
        setErrorMessage('Uh oh, something went wrong. Please try again later.');
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    };

    return (
        <>
            {errorMessage && (
                <Alert variant="danger" className='my-3 text-center error-message'>{errorMessage}</Alert>
            )}
            <div className='d-flex justify-content-center pb-2 fw-bold'>- OR -</div>
            <Row className='text-center paymentBtn'>
                <Col className="d-flex justify-content-center">
                    {canMakePayments && <>
                        <Button className='px-3' variant="dark" onClick={handleApplePayPayment}>
                            <FontAwesomeIcon icon={faApple} />&nbsp;Pay
                        </Button> &nbsp;&nbsp;&nbsp;
                    </>}
                    {orderInProgress ?
                        <Button className='px-3' disabled variant="dark">
                            <FontAwesomeIcon icon={faGoogle} />&nbsp;Pay
                        </Button> :
                        <GooglePayButton
                            environment={paymentMode}
                            paymentRequest={{
                                apiVersion: 2,
                                apiVersionMinor: 0,
                                allowedPaymentMethods: [
                                    {
                                        type: 'CARD',
                                        parameters: {
                                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                            allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
                                        },
                                        tokenizationSpecification: {
                                            type: 'PAYMENT_GATEWAY',
                                            parameters: {
                                                gateway: paymentOption,
                                                gatewayMerchantId: gatewayMerchantId,
                                                'stripe:publishableKey': `${publishKey}`,
                                                'stripe:version': '2020-08-27',
                                            },
                                        },
                                    },
                                ],
                                merchantInfo: {
                                    merchantId: 'BCR2DN4TRKDI3EIV',
                                    merchantName: 'MightyMeals',
                                },
                                transactionInfo: {
                                    totalPriceStatus: 'FINAL',
                                    totalPriceLabel: 'Total',
                                    totalPrice: `${cartTotal}`,
                                    currencyCode: 'USD',
                                    countryCode: 'US',
                                },
                                shippingAddressRequired: true,
                                callbackIntents: ['PAYMENT_AUTHORIZATION'],
                                shippingAddressParameters: {
                                    allowedCountryCodes: ['US'],
                                },
                            }}
                            onLoadPaymentData={handleLoadPaymentData}
                            onPaymentAuthorized={handlePaymentAuthorized}
                            onError={handleError}
                            existingPaymentMethodRequired={false}
                            buttonType="short"
                        />}
                </Col>
            </Row>
        </>
    )
}

