import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, Container, Alert, Modal, Spinner, Button } from 'react-bootstrap'
import AutoshipDietaryPage from './DietaryPage';
import PaymentMethods from './PaymentMethods';
import NotificationsPage from './NotificationsPage';
import OrderObj from '../../../features/orders/orderObj';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../../features/user/userSlice';
import { resetReferrals, selectAffiliate, selectVisit } from '../../../features/referrals/referralsSlice';
import { resetCustomer, selectCustomer } from '../../../features/customer/customerSlice';
import { selectPendingOrder, setPendingOrder } from '../../../features/checkout/checkoutSlice';
import CustomerObj from '../../../features/customer/customerObj';
import { clearCart, selectCartCoupon, selectCartItems, selectOrderInProgress, setCartCoupon, setCartUpdateInProgress, setOrderInProgress } from '../../../features/cart/cartSlice';
import OrdersAPI from '../../../API/ordersAPI';
import ProductObj from '../../../features/products/productObj';
import { selectProducts } from '../../../features/products/productsSlice';
import CartObj from '../../../features/cart/cartObj';
import TiktokPixel from 'tiktok-pixel';
import ShippingMethodObj from '../../../features/shipping/shippingMethodObj';
import CheckoutUtils from '../../../features/checkout/checkoutUtils';
import { selectCoupons, selectPointsCoupon, setPointsCoupon } from '../../../features/coupons/couponsSlice';
import { addOrder, loadOrders } from '../../../features/orders/ordersSlice';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { selectGeneralOptions, selectIsMobileRoute, selectLifeTimeAffiliate, selectVisitorID } from '../../../features/mobile/mobileSlice';
import { selectShippingMethods } from '../../../features/shipping/shippingSlice';
import CouponObj from '../../../features/coupons/couponObj';
import axios from 'axios';
import { mightyPointsToCurrency } from '../../../features/customer/mightyPointsUtils';
import { createCoupon, getCouponByCode } from '../../../API/coupons';
import { selectAutoShipData, selectOrderItems, selectSelectedDate, selectSelectedDates, setIsBulkOrder, setTurnOnAutoship } from '../core/autoShipSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import AutoShipAPI from '../core/autoshipAPI';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const AutoShipSettings: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMsg, setErrorMsg] = useState('');
    const [eventKey, setEventKey] = useState('0');
    const selectedDate = useSelector(selectSelectedDate);
    const selectedDates = useSelector(selectSelectedDates);
    const token = useSelector(selectToken);
    const coupons = useSelector(selectCoupons);
    const custData = useSelector(selectCustomer);
    const products = useSelector(selectProducts);
    const dietary = useSelector(selectAutoShipData);
    const orderItems = useSelector(selectOrderItems);
    const affiliateData = useSelector(selectAffiliate);
    const referralVisitData = useSelector(selectVisit);
    const affiliateVisitorID = useSelector(selectVisitorID);
    const shippingMethods = useSelector(selectShippingMethods);
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const lifeTimeAffiliate = useSelector(selectLifeTimeAffiliate);
    const orderInProgress = useSelector(selectOrderInProgress);
    const cartCoupon = CouponObj.createCoupon(useSelector(selectCartCoupon));
    const pointsCoupon = useSelector(selectPointsCoupon);
    const [applyChange, setApplyChange] = useState(false);
    const [placeOrder, setPlaceOrder] = useState(false);
    const paymentOption = getGeneralOptions?.payment_option;
    const paymentMode = getGeneralOptions?.payment_mode;
    const publishKey = paymentMode === 'live' ?
        process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY :
        process.env.REACT_APP_STAGE_STRIPE_PUBLISHABLE_KEY;
    const stripePromise = publishKey ? loadStripe(publishKey) : null;
    const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cart = new CartObj(useSelector(selectCartItems));
    const AppURL = useSelector(selectIsMobileRoute);
    const pendingOrderData = useSelector(selectPendingOrder);
    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);
    const billing_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
    const shipping_info = Object.keys(customer.data).length > 0 ? customer.data.shipping : {};
    let isShipping: ShippingMethodObj[] = [];
    const pointsBalance = customer.getMightyPoints();
    const isSelectedPickForMe = location.state && location.state.isSetting;
    const maxUsablePoints = customer.getMaxUsablePoints(
        cart.getSubtotal(null, null, null) as number
    );
    const mealsCoupon = coupons.filter((coupon: any) => coupon.code.startsWith('meal'));

    useEffect(() => {
        if (placeOrder && Object.keys(cart.items).length > 0) {
            submitAutoshipOrder();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeOrder, cart.items])

    const handleAccordionClick = (eventKey: string) => {
        if (!isSelectedPickForMe) {
            setEventKey(eventKey);
            window.scrollTo(0, 0);
        }
    };

    const handleFirstAccordian = () => {
        if (isSelectedPickForMe) {
            setPlaceOrder(true);
        } else {
            const nextEventKey = parseInt(eventKey) + 1;
            setEventKey(nextEventKey.toString());
            setPlaceOrder(false);
        }
    }

    const handleSecondAccordian = () => {
        const nextEventKey = parseInt(eventKey) + 1;
        setEventKey(nextEventKey.toString());
    }

    const handleThirdAccordian = () => {
        const nextEventKey = 0;
        setEventKey(nextEventKey.toString());
    }

    const addCouponToOrder = (order: OrderObj, shippingCost: number, newCoupon: any) => {
        if (!newCoupon) return;

        if (newCoupon.isPointsCoupon(customer)) {
            order.addPointsToMeta(
                maxUsablePoints,
                customer.data.mightypoints_discount_code
            );
        } else if (newCoupon.data.discount_type === 'smart_coupon') {
            order.addSmartCouponToMeta(
                newCoupon,
                cart.getTotal(products, shippingCost, null, customer) as number);
        }
        if (newCoupon.data && newCoupon.data.code) {
            order.data.coupon_lines = [{ "code": newCoupon.data.code }];
        }
    }

    const generateBrowserId = (): number => {
        const userAgent = navigator.userAgent;
        const language = navigator.language || (navigator as any).userLanguage || '';

        const fingerprint = `${userAgent} ${language}`;
        const hashedBrowserId = hashString(fingerprint);

        return hashedBrowserId;
    };

    const hashString = (data: string): number => {
        let hash = 0;

        if (data.length === 0) {
            return hash;
        }

        for (let i = 0; i < data.length; i++) {
            const char = data.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }

        return hash;
    };

    const getParameterByName = (name: string, url?: string): string | null => {
        if (!url) {
            url = window.location.href;
        }

        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    const getMatchedMethods = useCallback(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isShipping = [];

        const zip = (shipping_info && shipping_info.postcode) ? shipping_info.postcode : (billing_info && billing_info.postcode ? billing_info.postcode : '');

        let matchedMethods = [];
        let hasFreeDelivery = false;

        for (const method of shippingMethods) {
            const sm = new ShippingMethodObj(method);
            if (sm.isMatch(zip, cart, products, cartCoupon, customer)) {
                if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
                    hasFreeDelivery = false;
                    matchedMethods.push(sm);
                } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
                    if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
                        hasFreeDelivery = true;
                        matchedMethods.push(sm);
                    } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
                        matchedMethods.push(sm);
                        if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
                            isShipping.push(sm);
                        }
                    }
                }
            }
        }

        if (hasFreeDelivery) {
            matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
        }

        return matchedMethods;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billing_info, shipping_info, shippingMethods, cart, products, cartCoupon, customer]);

    const createOrderFromFormValues = (customerId: number, newCoupon: any) => {
        const shippingCode = getMatchedMethods();
        let deliveryDate = undefined;

        if (!shippingCode[0].data.ID) return null;

        const shippingMethod = ShippingMethodObj.getById(
            shippingMethods,
            shippingCode[0].data.ID
        );

        if (!shippingMethod) return null;

        if (!shippingMethod.isEmailDelivery() && !(
            cart.hasProductWithCategory('gift-card', products) ||
            cart.hasProductWithCategory('mighty-bucks-gift-card', products)
        )) {
            if (!selectedDate) return null;

            deliveryDate = new Date(selectedDate).getFullYear().toString() + '-' +
                ('0' + (new Date(selectedDate).getMonth() + 1)).slice(-2) + '-' +
                ('0' + new Date(selectedDate).getDate()).slice(-2);
        }

        let data = {
            "customer_id": customerId,
            "billing": {
                "first_name": billing_info.first_name,
                "last_name": billing_info.last_name,
                "address_1": billing_info.address_1,
                "address_2": billing_info.address_2,
                "city": billing_info.city,
                "country": "US",
                "state": billing_info.state,
                "postcode": billing_info.postcode,
                "email": billing_info.email,
                "phone": billing_info.phone
            },
            "shipping": {},
            "line_items": cart.getLineItems(products),
            "coupon_lines": [],
            "shipping_lines": [{
                "method_id": 'flat_rate',
                "method_title": shippingMethod.data.title,
                "total": shippingMethod.data.cost
            }],
            "customer_note": "Is Autoship Order",
            "status": "autoship",
            "payment_method": creditCardProcessor,
            "payment_method_title": OrderObj.getPaymentMethodTitle(creditCardProcessor),
            "meta_data": [{
                "key": "Chosen Pickup Location",
                "value": shippingMethod.data.title === 'UPS Shipping' ? 'UPS' : "I'm having my meals delivered"
            }, {
                'key': '_mm_rest_order',
                'value': "true"
            }, {
                'key': '_autoship_order_type',
                'value': "pick_for_me"
            }],
        }

        if (deliveryDate) {
            data.meta_data.push({
                "key": "_delivery_date",
                "value": deliveryDate
            });
        }

        if (!customer.hasMembership()) {
            data.meta_data.push({
                "key": "_wc_points",
                "value": cart.getPoints(products).toString()
            });
        }

        data.shipping = {
            "first_name": shipping_info.first_name ? shipping_info.first_name : billing_info.first_name,
            "last_name": shipping_info.last_name ? shipping_info.last_name : billing_info.last_name,
            "address_1": shipping_info.address_1 ? shipping_info.address_1 : billing_info.address_1,
            "address_2": shipping_info.address_2 ? shipping_info.address_2 : billing_info.address_2,
            "city": shipping_info.city ? shipping_info.city : billing_info.city,
            "country": "US",
            "state": shipping_info.state ? shipping_info.state : billing_info.state,
            "postcode": shipping_info.postcode ? shipping_info.postcode : billing_info.postcode,
        }

        let order = new OrderObj(data);

        if (newCoupon) {
            addCouponToOrder(order, parseFloat(shippingMethod.data.cost), newCoupon);
        }

        return order;
    }

    const createBulkOrderForAutoship = async (customerId: number) => {
        const shippingCode = getMatchedMethods();

        if (!shippingCode[0].data.ID) return null;

        const shippingMethod = ShippingMethodObj.getById(
            shippingMethods,
            shippingCode[0].data.ID
        );

        if (!shippingMethod) return null;

        // const generateMightyPointsDiscountCode = (customerId: number, index: number) => {
        //     const now = new Date();
        //     const year = now.getFullYear();
        //     const month = String(now.getMonth() + 1).padStart(2, '0');
        //     const day = String(now.getDate()).padStart(2, '0');
        //     const hours = String(now.getHours()).padStart(2, '0');
        //     const minutes = String(now.getMinutes()).padStart(2, '0');
        //     const seconds = String(now.getSeconds()).padStart(2, '0');
        //     const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

        //     const timestamp = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}_${milliseconds}_${index}`;

        //     return `wc_points_redemption_${customerId}_${timestamp}`;
        // };

        const allItems: any[] = [];

        const couponCreationPromises = await Promise.all(selectedDates.map(async (date: Date, index: number) => {
            let deliveryDate = undefined;
            let newCoupon: any = null;
            // let mightypointsDiscountCode = generateMightyPointsDiscountCode(customerId, index);
            deliveryDate = date.getFullYear().toString() + '-' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + date.getDate()).slice(-2);
            // const coupon_data = {
            //     "code": mightypointsDiscountCode,
            //     "amount": mightyPointsToCurrency(maxUsablePoints).toString(),
            //     "usage_limit": 1,
            //     "usage_limit_per_user": 1,
            //     "enable_free_shipping": false,
            //     "email_restrictions": [customer.data.email]
            // };

            const getCouponCodeForAutoApply = async (couponCode: any): Promise<any> => {
                const coupons = await getCouponByCode(couponCode);
                if (coupons.length > 0) {
                    let coupon = new CouponObj(coupons[0]);
                    let validationResult = coupon.validate(customer.data.email, customer, cart, products);
                    if (!validationResult.error) {
                        return coupons[0];
                    }
                }
                return null;
            }

            const setItems = orderItems[index].reduce((acc: any, items: any) => {
                const product = new ProductObj(items.product);
                const cartItemKey = product.data.id.toString();
                acc[cartItemKey] = {
                    product_id: product.data.id,
                    product_qty: items.quantity,
                    product_price: Number(product.data.price),
                };
                return acc;
            }, {});

            allItems.push(setItems);

            try {
                if (token && !cartCoupon && mealsCoupon.length > 0 && mealsCoupon.length > index && !customer.hasMembership()) {
                    const coupon = await getCouponCodeForAutoApply(mealsCoupon[index].code);
                    if (coupon) {
                        newCoupon = await CouponObj.createCoupon(coupon);
                    }
                }
                // else if (token && !cartCoupon && pointsBalance > 0 && !customer.hasMembership()) {
                //     const coupon = await createCoupon(token, coupon_data);
                //     newCoupon = await CouponObj.createCoupon(coupon);
                // }
                const allCartItems = new CartObj(allItems[index]);
                let data: any = {
                    customer_id: customerId,
                    payment_method: creditCardProcessor,
                    payment_method_title: OrderObj.getPaymentMethodTitle(creditCardProcessor),
                    billing: {
                        first_name: billing_info.first_name,
                        last_name: billing_info.last_name,
                        address_1: billing_info.address_1,
                        address_2: billing_info.address_2,
                        city: billing_info.city,
                        country: "US",
                        state: billing_info.state,
                        postcode: billing_info.postcode,
                        email: billing_info.email,
                        phone: billing_info.phone
                    },
                    shipping: {
                        first_name: shipping_info.first_name ? shipping_info.first_name : billing_info.first_name,
                        last_name: shipping_info.last_name ? shipping_info.last_name : billing_info.last_name,
                        address_1: shipping_info.address_1 ? shipping_info.address_1 : billing_info.address_1,
                        address_2: shipping_info.address_2 ? shipping_info.address_2 : billing_info.address_2,
                        city: shipping_info.city ? shipping_info.city : billing_info.city,
                        country: "US",
                        state: shipping_info.state ? shipping_info.state : billing_info.state,
                        postcode: shipping_info.postcode ? shipping_info.postcode : billing_info.postcode,
                    },
                    line_items: allCartItems.getLineItems(products),
                    coupon_lines: newCoupon ? [{
                        code: newCoupon.data.code
                    }] : [],
                    shipping_lines: [{
                        method_id: 'flat_rate',
                        method_title: shippingMethod.data.title,
                        total: shippingMethod.data.cost
                    }],
                    customer_note: "Is Autoship Order",
                    status: "autoship",
                    meta_data: [{
                        key: "Chosen Pickup Location",
                        value: shippingMethod.data.title === 'UPS Shipping' ? 'UPS' : "I'm having my meals delivered"
                    }, {
                        key: '_mm_rest_order',
                        value: "true"
                    }, {
                        key: '_autoship_order_type',
                        value: "pick_for_me"
                    }, {
                        key: "_autoship_pick_for_me_bulk_orders",
                        value: "1"
                    }],
                };

                if (date) {
                    data.meta_data.push({
                        key: "_delivery_date",
                        value: deliveryDate
                    });
                }

                if (!customer.hasMembership()) {
                    data.meta_data.push({
                        key: "_wc_points",
                        value: cart.getPoints(products).toString()
                    });
                }

                return data;
            } catch (error) {
                console.error("Error creating coupon or order:", error);
                throw error;
            }
        }));

        return couponCreationPromises;
    };

    const submitAutoshipOrder = () => {
        let checkoutCtxt: Record<string, any> = {
            order: new OrderObj({}),
            token: token,
            customerId: 'id' in customer.data ? customer.data.id : null,
            authNonce: '',
            affiliateData: affiliateData,
            referralVisitData: referralVisitData
        }

        let newCoupon: any = null;

        setErrorMsg('');
        dispatch(setOrderInProgress(true));

        const handleMightyPointsAndCoupon = () => {
            return new Promise<void>(async (resolve, reject) => {

                if (pointsCoupon) {
                    if (pointsCoupon.amount === mightyPointsToCurrency(maxUsablePoints).toString()) {
                        dispatch(setCartCoupon({ token: '', coupon: pointsCoupon }));
                        newCoupon = { token: '', coupon: pointsCoupon };
                        dispatch(setCartUpdateInProgress(false));
                        resolve();
                        return;
                    } else {
                        dispatch(setPointsCoupon(null));
                        dispatch(setCartUpdateInProgress(false));
                    }
                }

                if (!('mightypoints_discount_code' in customer.data) ||
                    !customer.data.mightypoints_discount_code ||
                    !customer.data.email) {
                    dispatch(setCartUpdateInProgress(false));
                    reject('Mighty points discount code or email missing');
                    return;
                }

                dispatch(setCartUpdateInProgress(true));
                const coupon_data = {
                    "code": customer.data.mightypoints_discount_code,
                    "amount": mightyPointsToCurrency(maxUsablePoints).toString(),
                    "usage_limit": 1,
                    "usage_limit_per_user": 1,
                    "enable_free_shipping": false,
                    "email_restrictions": [customer.data.email]
                };

                try {
                    const coupon = await createCoupon(token, coupon_data);
                    if (coupon && coupon.id) {
                        dispatch(setPointsCoupon(coupon));
                        dispatch(setCartCoupon({ token: '', coupon: coupon }));
                        newCoupon = { token: '', coupon: coupon };
                        dispatch(setCartUpdateInProgress(false));
                        resolve();
                    } else {
                        dispatch(setCartUpdateInProgress(false));
                        reject('Failed to create coupon');
                    }
                } catch (error) {
                    dispatch(setCartUpdateInProgress(false));
                    reject(error);
                }
            });
        };

        Promise.resolve().then(async () => {
            if (token && !cartCoupon && pointsBalance > 0 && !customer.hasMembership()) {
                await handleMightyPointsAndCoupon();
                newCoupon = await CouponObj.createCoupon(newCoupon.coupon);
            }

            const res = await OrdersAPI.checkOrderQTY(checkoutCtxt.token, [Object.keys(cart.items)]);

            const outOfStockProducts = res.data.filter((item: { message: string; stock: null; }) => item.message === "Out of stock" && item.stock !== null);

            if (outOfStockProducts.length > 0) {
                const outOfStockProductNames = outOfStockProducts.map((product: { product_id: any; }) => {
                    const productsById = ProductObj.getById(products, Number(product.product_id));
                    return productsById?.data.name ? productsById?.data.name : `Unknown Product`;
                }).join(', ');

                setErrorMsg(`The following products are out of stock: ${outOfStockProductNames}. Cannot proceed with the order.`);
                throw new Error(`The following products are out of stock: ${outOfStockProductNames}. Cannot proceed with the order.`);
            }

            let order = createOrderFromFormValues(checkoutCtxt.customerId, newCoupon);

            if (!order) {
                setErrorMsg("An error has occurred while creating an order. Please try again later");
                throw new Error("An error has occurred. Please try again later")
            };

            TiktokPixel.track(
                'PlaceAnOrder',
                {
                    content_id: cart.getProductIDs(),
                    content_name: cart.getProductNames(products),
                    quantity: cart.getItemCount(),
                    content_type: 'product',
                    value: checkoutCtxt.order.data.total,
                    currency: 'USD'
                }
            );

            if (Object.keys(pendingOrderData).length > 0) {
                const shippingCode = getMatchedMethods();
                // Make deep copy of pending order
                let updatedOrder = new OrderObj(JSON.parse(JSON.stringify(pendingOrderData)));
                if (updatedOrder.ageInSeconds() < 3600) {
                    updatedOrder.updateData(order.data);
                    if (newCoupon && shippingCode[0].data.ID) {
                        const shippingMethod = ShippingMethodObj.getById(
                            shippingMethods,
                            shippingCode[0].data.ID
                        );

                        if (shippingMethod) {
                            addCouponToOrder(updatedOrder, parseFloat(shippingMethod.data.cost), newCoupon);
                        }
                    } else {
                        updatedOrder.removePointsFromMeta();
                        updatedOrder.removeSmartCouponFromMeta();
                    }

                    return OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id, updatedOrder.data);
                } else {
                    // Pending order is over an hour old. Mark it canceled
                    try {
                        OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id,
                            { status: 'cancelled' });
                    } catch (e) {
                        setErrorMsg(`Error while canceling order.`)
                        OrdersAPI.createOrderNote(checkoutCtxt.token, pendingOrderData.id, `Error while canceling order (Pending order is over an hour old): ${e}`);
                    }
                    dispatch(setPendingOrder({}));
                }
            }
            console.log("before create", order.data);
            return OrdersAPI.createOrder(checkoutCtxt.token, order.data);
        }).then((orderData) => {
            console.log("after create", orderData);
            if ('code' in orderData && 'message' in orderData) {
                let errorMessage = orderData.message;

                const isHtml = /<\/?[a-z][\s\S]*>/i.test(errorMessage);

                if (isHtml) {
                    const tempDiv = document.createElement("div");
                    tempDiv.innerHTML = errorMessage;
                    errorMessage = tempDiv.textContent || tempDiv.innerText;
                }
                setErrorMsg(errorMessage)
                if (orderData.id) {
                    OrdersAPI.createOrderNote(checkoutCtxt.token, orderData.id, orderData.message);
                }
                throw new Error(errorMessage);
            }
            if (!('id' in orderData)) {
                setErrorMsg('An error has occurred while creating an order id. Please try again later.')
                throw new Error('An error has occurred. Please try again later.');
            }

            dispatch(setPendingOrder(orderData));
            // Deep copy order and add to context
            checkoutCtxt.order = new OrderObj(JSON.parse(JSON.stringify(orderData)));

        }).then(async () => {
            const browserId = generateBrowserId();
            const userClickID = getParameterByName('fbclid');
            let ipAddress = '';
            try {
                const res = await axios.get("https://api.ipify.org/?format=json");
                ipAddress = res.data.ip;
            } catch (error) {
                console.error("Error fetching IP address from api.ipify", error);
            }

            // Order is complete. Track purchase with FB Pixel and GA4
            ReactPixel.track(
                'Purchase',
                {
                    currency: 'USD',
                    num_items: cart.getItemCount(),
                    content_type: 'product',
                    content_ids: cart.getProductIDs(),
                    content_name: cart.getProductNames(products),
                    value: checkoutCtxt.order.data.total,
                    click_id: userClickID,
                    browser_id: browserId,
                    ip_address: ipAddress,
                }
            );
            ReactGA.event(
                'purchase',
                {
                    currency: 'USD',
                    transaction_id: checkoutCtxt.order.data.id,
                    value: checkoutCtxt.order.data.total,
                    items: cart.getItemsForGA(products)
                }
            );
            TiktokPixel.track(
                'CompletePayment',
                {
                    content_id: cart.getProductIDs(),
                    content_name: cart.getProductNames(products),
                    quantity: cart.getItemCount(),
                    content_type: 'product',
                    value: checkoutCtxt.order.data.total,
                    currency: 'USD'
                }
            );

            // Reddit Pixel tracking
            const email = customer.data.email ?
                customer.data.email : checkoutCtxt.order.data.billing.email ?
                    checkoutCtxt.order.data.billing.email : '';
            const items = await Object.values(cart.items).map((item: any) => {
                const product = ProductObj.getById(products, item.product_id);
                return {
                    id: product?.data.id,
                    name: product?.data.name,
                    category: product?.data.categories,
                }
            });
            (window as any).rdt('track', 'Purchase', {
                email: email,
                currency: 'USD',
                itemCount: cart.getItemCount(),
                transactionId: checkoutCtxt.order.data.id,
                value: checkoutCtxt.order.data.total,
                products: items
            });

            CheckoutUtils.maybePostReferral(customer, checkoutCtxt, lifeTimeAffiliate, affiliateVisitorID);
            dispatch(setPendingOrder({}));
            dispatch(clearCart(checkoutCtxt.token));
            dispatch(resetCustomer());
            dispatch(setPointsCoupon(null));
            dispatch(resetReferrals());

            const orderStatus = 'autoship';
            let orderData: Record<string, any> = { status: orderStatus };
            if ('authNetTransId' in checkoutCtxt) {
                // Make deep copy of the meta_data
                orderData.meta_data = JSON.parse(JSON.stringify(checkoutCtxt.order.data.meta_data));
                orderData.meta_data.push({
                    "key": "_authorize_net_trans_id",
                    "value": checkoutCtxt.authNetTransId
                });
            }
            return OrdersAPI.updateOrder(checkoutCtxt.token, checkoutCtxt.order.data.id, orderData);
        }).then((orderData) => {
            // Add the new order to orders slice
            if (orderData) {
                checkoutCtxt.order = new OrderObj(orderData);
                dispatch(addOrder(orderData));
            }
            navigate(`/autoship${AppURL}`);
        }).catch((e) => {
            setErrorMsg(e.message);
        }).finally(() => {
            setPlaceOrder(false);
            dispatch(setOrderInProgress(false));
            dispatch(setTurnOnAutoship(false));
        });
    }

    const handleBulkOrders = () => {
        if (!dietary) {
            setErrorMsg('Invalid cart or dietary data.');
            handleThirdAccordian();
            return;
        }

        if (orderItems.length <= 0) {
            setErrorMsg('Please make sure to complete your Dietary Needs & Goals settings. Try again later.');
            handleThirdAccordian();
            return;
        }

        const checkoutCtxt: Record<string, any> = {
            order: new OrderObj({}),
            token,
            customerId: customer?.data?.id || null,
            authNonce: '',
            affiliateData,
            referralVisitData
        };

        setErrorMsg('');
        dispatch(setOrderInProgress(true));

        Promise.resolve().then(async () => {
            // Check product quantities and handle out-of-stock products
            const res = await OrdersAPI.checkOrderQTY(checkoutCtxt.token, [Object.keys(cart.items)]);
            const outOfStockProducts = res.data.filter((item: { message: string; stock: any; }) => item.message === "Out of stock" && item.stock !== null);

            if (outOfStockProducts.length > 0) {
                const outOfStockProductNames = outOfStockProducts.map((product: { product_id: any; }) => {
                    const productData = ProductObj.getById(products, Number(product.product_id));
                    return productData?.data?.name || 'Unknown Product';
                }).join(', ');

                const errorMsg = `The following products are out of stock: ${outOfStockProductNames}. Cannot proceed with the order.`;
                setErrorMsg(errorMsg);
                throw new Error(errorMsg);
            }

            // Create order
            const order = await createBulkOrderForAutoship(checkoutCtxt.customerId);
            if (!order) {
                const errorMsg = "An error occurred while creating the order. Please try again later.";
                setErrorMsg(errorMsg);
                throw new Error(errorMsg);
            }

            return AutoShipAPI.createOrder(order);
        }).then(async (orderData) => {
            if (!orderData.create || !Array.isArray(orderData.create) || orderData.create.length === 0) {
                const errorMsg = 'An error occurred while creating the order. Please try again later.';
                setErrorMsg(errorMsg);
                throw new Error(errorMsg);
            }

            const order = orderData.create[0];
            if (order.code && order.message) {
                let errorMessage = order.message;

                // Sanitize HTML if necessary
                if (/<\/?[a-z][\s\S]*>/i.test(errorMessage)) {
                    const tempDiv = document.createElement("div");
                    tempDiv.innerHTML = errorMessage;
                    errorMessage = tempDiv.textContent || tempDiv.innerText;
                }
                setErrorMsg(errorMessage);
                throw new Error(errorMessage);
            }

            if (!order.id) {
                const errorMsg = 'An error occurred while generating the order ID. Please try again later.';
                setErrorMsg(errorMsg);
                throw new Error(errorMsg);
            }

            if (Array.isArray(order.line_items) && order.line_items.length > 0) {
                await dispatch(addOrder(order));
            } else {
                const errorMsg = "Order data is not in the expected format.";
                setErrorMsg(errorMsg);
                throw new Error(errorMsg);
            }
        }).then(async () => {
            // Post-referral data and finalize the order process
            CheckoutUtils.maybePostReferral(customer, checkoutCtxt, lifeTimeAffiliate, affiliateVisitorID);
            dispatch(setTurnOnAutoship(false));
            await dispatch(loadOrders({ token, isAutoship: true, customerId: customer?.data?.id }));
            dispatch(clearCart(checkoutCtxt.token));
            dispatch(resetCustomer());
            dispatch(resetReferrals());
            dispatch(setIsBulkOrder(true));
            navigate(`/autoship${AppURL}`);
        }).catch((e: any) => {
            setErrorMsg("An error occurred. Please try again later.");
            console.error(e.message);
        }).finally(() => {
            setPlaceOrder(false);
            dispatch(setOrderInProgress(false));
        });
    };

    const onHideHandle = () => {
        setErrorMsg('');
        setApplyChange(false);
        dispatch(setCartUpdateInProgress(false));
    }

    return (
        <Container className='autoshipContainer'>
            <div className='d-flex justify-content-center align-items-center autoshipPage pb-4'>
                <span className='autoshipPageHeader'>Order Planner Settings</span>
                {location.pathname === '/autoship/settings' &&
                    <div className='settingclose'>
                        <FontAwesomeIcon
                            color='black'
                            className='cursor-pointer'
                            onClick={() => navigate('/autoship')}
                            icon={faClose}
                        ></FontAwesomeIcon>
                    </div>}
            </div>
            <Modal show={applyChange} onHide={onHideHandle}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to place this order?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" disabled={orderInProgress} onClick={() => setApplyChange(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="outline-dark"
                        disabled={orderInProgress}
                        onClick={submitAutoshipOrder}>
                        {orderInProgress ?
                            <>
                                Placing Order ...&nbsp;&nbsp;
                                <Spinner animation="border" as="span" size="sm" />
                            </> : 'Place Order'}
                    </Button>
                </Modal.Footer>
            </Modal>
            {errorMsg &&
                <div className='d-flex justify-content-center align-items-center autoshipPage pb-4'>
                    <Alert variant="danger" className='my-3'>{errorMsg}</Alert>
                </div>}
            <Accordion defaultActiveKey={'0'} activeKey={location.search === '?payment' ? '2' : eventKey} onSelect={(e: any) => handleAccordionClick(e)} flush>
                {location.search !== '?payment' &&
                    <Accordion.Item eventKey="0" className='border my-3 bg-light text-dark'>
                        <Accordion.Header>
                            DIETARY NEEDS & GOALS
                        </Accordion.Header>
                        <Accordion.Body className='p-4 mt-md-3'>
                            <AutoshipDietaryPage handleFirstAccordian={handleFirstAccordian} />
                        </Accordion.Body>
                    </Accordion.Item>}
                {!isSelectedPickForMe &&
                    <>
                        {location.search !== '?payment' &&
                            <Accordion.Item eventKey="1" className='border mb-3 bg-light text-dark'>
                                <Accordion.Header>ORDER PLANNER NOTIFICATIONS</Accordion.Header>
                                <Accordion.Body className='p-4 mt-md-3'>
                                    <NotificationsPage handleSecondAccordian={handleSecondAccordian} />
                                </Accordion.Body>
                            </Accordion.Item>}
                        <Accordion.Item eventKey="2" className='border my-3 bg-light text-dark'>
                            <Accordion.Header>PAYMENT METHODS</Accordion.Header>
                            <Accordion.Body className='p-4 mt-md-3'>
                                <Elements stripe={stripePromise}>
                                    <PaymentMethods handleThirdAccordian={handleThirdAccordian} handleBulkOrders={handleBulkOrders} />
                                </Elements>
                            </Accordion.Body>
                        </Accordion.Item>
                        {location.search !== '?payment' &&
                            <Accordion.Item eventKey="3" className='border my-3 bg-light text-dark'>
                                <Accordion.Header>
                                    SUPPORT & HELP
                                </Accordion.Header>
                                <Accordion.Body className='p-4 mt-md-3 d-flex justify-content-center'>
                                    <Button
                                        as={Link as any}
                                        target='_blank'
                                        to="https://mightymeals.freshdesk.com/support/home">
                                        Contact Us
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>}
                    </>}
            </Accordion>
        </Container>
    )
}

export default AutoShipSettings;