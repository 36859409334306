import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import {
  clearCart,
  selectCartCoupon,
  selectCartDeliveryDate,
  selectCartItems,
  selectCartZipcode,
  selectOrderInProgress,
  setOrderInProgress
} from '../../features/cart/cartSlice';
import CartObj from '../../features/cart/cartObj';
import OrderObj from '../../features/orders/orderObj';
import CustomerObj from '../../features/customer/customerObj';
import {
  resetCustomer,
  selectCustomer,
  selectCustomerIsLoaded,
  // setCustomer
} from '../../features/customer/customerSlice';
import { selectToken } from '../../features/user/userSlice';
import BillingDetails from './BillingDetails';
import ShippingDetails from './ShippingDetails';
import PasswordInput from './PasswordInput';
import DeliveryNotesInput from './DeliveryNotesInput';
import YourOrder from './YourOrder';
import CreditCardDetails from './CreditCardDetails';
import OrdersAPI from '../../API/ordersAPI';
import { addOrder } from '../../features/orders/ordersSlice';
import { selectProducts } from '../../features/products/productsSlice';
import { FormValues } from './interfaces';
import validateForm from './validateForm';
import CouponObj from '../../features/coupons/couponObj';
import { setPointsCoupon } from '../../features/coupons/couponsSlice';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import DeliveryDateInput from './DeliveryDateInput';
import DeliveryObj from '../../features/delivery/deliveryObj';
import GiftCardInput from './GiftCardInput';
import { selectDeliveryData } from '../../features/delivery/deliverySlice';
import { selectGuestEmail } from '../../features/guest/guestSlice';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import CheckoutUtils from '../../features/checkout/checkoutUtils';
import { selectPendingOrder, setPendingOrder } from '../../features/checkout/checkoutSlice';
import { useAcceptJs } from 'react-acceptjs';
import './checkoutForm.scss';
import { resetReferrals, selectAffiliate, selectVisit } from '../../features/referrals/referralsSlice';
import Helper from '../../utils/Helper';
import { selectGeneralOptions, selectIsMobileRoute, selectLifeTimeAffiliate, selectSavedCardData, selectVisitorID, setSavedCardData } from '../../features/mobile/mobileSlice';
import TiktokPixel from 'tiktok-pixel';
import axios from 'axios';
import ProductObj from '../../features/products/productObj';
import SavePaymentData from './SavePaymentData';
import AuthSavedCards from './AuthSavedCards';
import AuthNetAPI from '../../API/authNetAPI';
import creditCardType from 'credit-card-type';
import PaymentOptions from './PaymentOptions';
// import CustomerAPI from '../../API/customerAPI';

const stageAuthAPILoginID = process.env.REACT_APP_STAGE_AUTHORIZE_NET_API_LOGIN_ID;
const prodAuthAPILoginID = process.env.REACT_APP_PROD_AUTHORIZE_NET_API_LOGIN_ID;
const stageAuthClientKey = process.env.REACT_APP_STAGE_AUTHORIZE_NET_PUBLIC_CLIENT_KEY;
const prodAuthClientKey = process.env.REACT_APP_PROD_AUTHORIZE_NET_PUBLIC_CLIENT_KEY;

export default function CheckoutForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const pendingOrderData = useSelector(selectPendingOrder);
  let token = useSelector(selectToken);
  const guestEmail = useSelector(selectGuestEmail);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cart = new CartObj(useSelector(selectCartItems));
  const cartZipcode = useSelector(selectCartZipcode);
  const cartDeliveryDate = useSelector(selectCartDeliveryDate);
  const cartCoupon = CouponObj.createCoupon(useSelector(selectCartCoupon));
  const custData = useSelector(selectCustomer)
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);
  const products = useSelector(selectProducts);
  const shippingMethods = useSelector(selectShippingMethods);
  const customerIsLoaded = useSelector(selectCustomerIsLoaded);
  const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
  const billing_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
  const shipping_info = Object.keys(customer.data).length > 0 ? customer.data.shipping : {};
  const elements = useElements();
  const stripe = useStripe();
  const orderInProgress = useSelector(selectOrderInProgress);
  const maxUsablePoints = customer.getMaxUsablePoints(
    cart.getSubtotal(null, null, null) as number
  );
  const affiliateData = useSelector(selectAffiliate);
  const referralVisitData = useSelector(selectVisit);
  const affiliateVisitorID = useSelector(selectVisitorID);
  const AppURL = useSelector(selectIsMobileRoute);
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const getSavedCardData = useSelector(selectSavedCardData);
  const paymentOption = getGeneralOptions?.payment_option;
  const paymentMode = getGeneralOptions?.payment_mode;
  const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
  const lifeTimeAffiliate = useSelector(selectLifeTimeAffiliate);
  const totalRef = useRef<HTMLTableCellElement>(null);
  const [totalValue, setTotalValue] = useState<string | null>(null);
  const [useNewPayment, setUseNewPayment] = useState(false);
  const [showSavedCards, setShowSavedCards] = useState(false);
  const authNetEnv = paymentMode === 'live' ? 'PRODUCTION' : 'SANDBOX';
  const authorizeAPILoginID = paymentMode === 'live' ? prodAuthAPILoginID : stageAuthAPILoginID;
  const authorizeClientKey = paymentMode === 'live' ? prodAuthClientKey : stageAuthClientKey;
  const authNetData = {
    apiLoginID: authorizeAPILoginID || '',
    clientKey: authorizeClientKey || ''
  }
  const authorizeNet = useAcceptJs({
    environment: authNetEnv,
    authData: authNetData
  });

  useEffect(() => {
    const savedCardData = getSavedCardData?.user_cim !== "No CIM Data Available" && getSavedCardData?.status === 200;
    if (token && creditCardProcessor === 'authorize.net' && savedCardData) {
      setShowSavedCards(true);
      setUseNewPayment(false);
    } else {
      setShowSavedCards(false);
      setUseNewPayment(true);
    }
  }, [token, getSavedCardData, creditCardProcessor]);

  useEffect(() => {
    if (errorMsg && (errorMsg.startsWith("HTTP Error") || errorMsg.startsWith("http error"))) {
      setErrorMsg('Unable to process payment, please refresh the page and try again. If you continue to experience a payment issue please call us at (833) 406-3257');
    }
  }, [errorMsg])

  const addCouponToOrder = (order: OrderObj, shippingCost: number) => {
    if (!cartCoupon) return;

    if (cartCoupon.isPointsCoupon(customer)) {
      order.addPointsToMeta(
        maxUsablePoints,
        customer.data.mightypoints_discount_code
      );
    } else if (cartCoupon.data.discount_type === 'smart_coupon') {
      order.addSmartCouponToMeta(
        cartCoupon,
        cart.getTotal(products, shippingCost, null, customer) as number);
    }
    order.data.coupon_lines = [{ "code": cartCoupon.data.code }]

  }

  const generateBrowserId = (): number => {
    const userAgent = navigator.userAgent;
    const language = navigator.language || (navigator as any).userLanguage || '';

    const fingerprint = `${userAgent} ${language}`;
    const hashedBrowserId = hashString(fingerprint);

    return hashedBrowserId;
  };

  const hashString = (data: string): number => {
    let hash = 0;

    if (data.length === 0) {
      return hash;
    }

    for (let i = 0; i < data.length; i++) {
      const char = data.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return hash;
  };

  const getParameterByName = (name: string, url?: string): string | null => {
    if (!url) {
      url = window.location.href;
    }

    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const createOrderFromFormValues = (values: FormValues, customerId: number) => {
    let deliveryDate = undefined;

    if (!values.shipping_method) return null;

    const shippingMethod = ShippingMethodObj.getById(
      shippingMethods,
      values.shipping_method
    );

    if (!shippingMethod) return null;

    if (!shippingMethod.isEmailDelivery() && !(
      cart.hasProductWithCategory('gift-card', products) ||
      cart.hasProductWithCategory('mighty-bucks-gift-card', products)
    )) {
      if (!values.delivery_date) return null;

      deliveryDate = values.delivery_date.getFullYear().toString() + '-' +
        ('0' + (values.delivery_date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + values.delivery_date.getDate()).slice(-2);
    }

    let data = {
      "customer_id": customerId,
      "billing": {
        "first_name": values.billing_first_name,
        "last_name": values.billing_last_name,
        "address_1": values.billing_address_1,
        "address_2": values.billing_address_2,
        "city": values.billing_city,
        "country": "US",
        "state": values.billing_state,
        "postcode": values.billing_postcode,
        "email": values.email,
        "phone": values.phone
      },
      "shipping": {},
      "line_items": cart.getLineItems(products, cartCoupon),
      "coupon_lines": [],
      "shipping_lines": [{
        "method_id": 'flat_rate',
        "method_title": shippingMethod.data.title,
        "total": shippingMethod.data.cost
      }],
      "customer_note": values.delivery_notes,
      "payment_method": creditCardProcessor,
      "payment_method_title": OrderObj.getPaymentMethodTitle(creditCardProcessor),
      "meta_data": [{
        "key": "Chosen Pickup Location",
        "value": shippingMethod.data.title === 'UPS Shipping' ? 'UPS' : "I'm having my meals delivered"
      }, {
        'key': '_mm_rest_order',
        'value': "true"
      }],
    }

    if (deliveryDate) {
      data.meta_data.push({
        "key": "_delivery_date",
        "value": deliveryDate
      });
    }

    if (!customer.hasMembership()) {
      data.meta_data.push({
        "key": "_wc_points",
        "value": cart.getPoints(products).toString()
      });
    }

    if (values.use_shipping) {
      data.shipping = {
        "first_name": values.shipping_first_name,
        "last_name": values.shipping_last_name,
        "address_1": values.shipping_address_1,
        "address_2": values.shipping_address_2,
        "city": values.shipping_city,
        "country": "US",
        "state": values.shipping_state,
        "postcode": values.shipping_postcode
      }
    } else {
      data.shipping = {
        "first_name": values.billing_first_name,
        "last_name": values.billing_last_name,
        "address_1": values.billing_address_1,
        "address_2": values.billing_address_2,
        "city": values.billing_city,
        "country": "US",
        "state": values.billing_state,
        "postcode": values.billing_postcode
      }
    }

    let order = new OrderObj(data);

    if (cartCoupon) {
      addCouponToOrder(order, parseFloat(shippingMethod.data.cost));
    }

    if (cart.hasProductWithCategory('gift-card', products) || cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
      if (values.send_gift_card) {
        order.addGiftCardMeta(values.gift_card_email,
          values.gift_card_msg, true);
      } else {
        order.addGiftCardMeta(values.email);
      }
    }
    return order;
  }

  const initialEmailValue = () => {
    if (token && billing_info && billing_info.email) {
      return 'email' in billing_info ? billing_info.email : ''
    } else if (token && customer && customer?.data?.email) {
      return customer?.data ? customer?.data?.email : ''
    }

    return guestEmail;
  }

  const initialValues = {
    email: initialEmailValue(),
    phone: billing_info && 'phone' in billing_info ? Helper.formatPhoneNo(billing_info.phone) : '',
    opt_in_marketing_text_messages: customer.data.opt_in_marketing_text_messages ? customer.data.opt_in_marketing_text_messages : true,
    billing_first_name: billing_info && 'first_name' in billing_info ? billing_info.first_name : '',
    billing_last_name: billing_info && 'last_name' in billing_info ? billing_info.last_name : '',
    billing_address_1: billing_info && 'address_1' in billing_info ? billing_info.address_1 : '',
    billing_address_2: billing_info && 'address_2' in billing_info ? billing_info.address_2 : '',
    billing_city: billing_info && 'city' in billing_info ? billing_info.city : '',
    billing_state: billing_info && 'state' in billing_info ? billing_info.state : '',
    billing_postcode: billing_info && 'postcode' in billing_info ? billing_info.postcode : cartZipcode,
    use_shipping: shipping_info && 'postcode' in shipping_info && shipping_info.postcode.length,
    shipping_first_name: shipping_info && 'first_name' in shipping_info ? shipping_info.first_name : '',
    shipping_last_name: shipping_info && 'last_name' in shipping_info ? shipping_info.last_name : '',
    shipping_address_1: shipping_info && 'address_1' in shipping_info ? shipping_info.address_1 : '',
    shipping_address_2: shipping_info && 'address_2' in shipping_info ? shipping_info.address_2 : '',
    shipping_city: shipping_info && 'city' in shipping_info ? shipping_info.city : '',
    shipping_state: shipping_info && 'state' in shipping_info ? shipping_info.state : '',
    shipping_postcode: shipping_info && 'postcode' in shipping_info ? shipping_info.postcode : '',
    password: '',
    delivery_notes: '',
    delivery_date: cartDeliveryDate,
    shipping_method: undefined,
    send_gift_card: '',
    gift_card_email: '',
    gift_card_msg: '',
    cartTotal: 0,
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    save_payment: false,
  };

  const validate = (values: FormValues) => {
    const setShowCard = showSavedCards ? useNewPayment ? true : false : true;
    const shippingMethodID = values?.shipping_method;
    const shippingMethodInputs = document.getElementsByName('shipping-method');

    const isUPSOrFreeShipping = (title: string) =>
      title === 'UPS Shipping' || title === 'UPS Free Shipping' || title === 'Free Shipping';

    if (shippingMethodInputs.length > 0) {
      const firstShippingMethodValue = (shippingMethodInputs[0] as HTMLInputElement)?.value;

      if (firstShippingMethodValue && shippingMethodID !== Number(firstShippingMethodValue)) {
        const shippingMethodByDate = ShippingMethodObj.getById(shippingMethods, Number(firstShippingMethodValue));
        return validateForm(creditCardProcessor, values, token, deliveryInfo, cart, products, isUPSOrFreeShipping(shippingMethodByDate?.data.title), totalValue, setShowCard);
      }
    }

    const shippingMethodByDate = ShippingMethodObj.getById(shippingMethods, Number(shippingMethodID));
    return validateForm(creditCardProcessor, values, token, deliveryInfo, cart, products, isUPSOrFreeShipping(shippingMethodByDate?.data.title), totalValue, setShowCard);
  }

  const onSubmit = (values: FormValues) => {
    const setShowCard = showSavedCards ? useNewPayment ? false : true : false;
    handleOnSubmit(values, setShowCard);
  }

  const handleOnSubmit = (values: FormValues, isSavedPayment: boolean) => {
    let checkoutCtxt: Record<string, any> = {
      order: new OrderObj({}),
      token: token,
      customerId: 'id' in customer.data ? customer.data.id : null,
      authNonce: '',
      affiliateData: affiliateData,
      referralVisitData: referralVisitData
    }

    setErrorMsg('');
    dispatch(setOrderInProgress(true));

    // const optInChanged = customer.data.opt_in_marketing_text_messages !== values.opt_in_marketing_text_messages;

    // if (optInChanged) {
    //   CustomerAPI.updateCustomerAccount({
    //     customer_id: checkoutCtxt.customerId,
    //     token: checkoutCtxt.token,
    //     first_name: values.billing_first_name,
    //     last_name: values.billing_last_name,
    //     email: values.email,
    //     opt_in_marketing_text_messages: values.opt_in_marketing_text_messages === true,
    //   }).then((response) => {
    //     if ('code' in response) {
    //       console.error(response);
    //       setErrorMsg("An error has occured. Please try again later.");
    //     } else if ('id' in response) {
    //       dispatch(setCustomer(response));
    //     }
    //   });
    // }

    Promise.resolve().then(() => {
      // If we need to create an account, do so, and authenticate
      if (!token && values.password) {
        try {
          return CheckoutUtils.registerAndAuthenticate(values.email, values.password,
            dispatch, checkoutCtxt);
        } catch (error) {
          console.error("Error from register and authenticate", error);
          setErrorMsg("Error from register and authenticate. Please try again later.");
          throw new Error(`${error}`);
        }
      }
    }).then(async () => {
      if (totalValue && parseFloat(totalValue.replace('$', '')) > 0) {
        // If using authorize.net, get Auth Nonce
        if (creditCardProcessor === 'authorize.net' && !isSavedPayment) {
          try {
            return CheckoutUtils.getAuthNetNonce(values.cardNumber, values.cardCvc,
              values.cardExpiry, authorizeNet, checkoutCtxt);
          } catch (error) {
            console.error("Error from AuthNet API call", error);
            setErrorMsg("Error processing payment. Please try again later.");
            throw new Error(`${error}`);
          }
        }
      }
    }).then(async () => {
      const res = await OrdersAPI.checkOrderQTY(checkoutCtxt.token, [Object.keys(cart.items)]);

      const outOfStockProducts = res.data.filter((item: { message: string; stock: null; }) => item.message === "Out of stock" && item.stock !== null);

      if (outOfStockProducts.length > 0) {
        const outOfStockProductNames = outOfStockProducts.map((product: { product_id: any; }) => {
          const productsById = ProductObj.getById(products, Number(product.product_id));
          return productsById?.data.name ? productsById?.data.name : `Unknown Product`;
        }).join(', ');

        setErrorMsg(`The following products are out of stock: ${outOfStockProductNames}. Cannot proceed with the order.`);
        throw new Error(`The following products are out of stock: ${outOfStockProductNames}. Cannot proceed with the order.`);
      }

      let order = createOrderFromFormValues(values, checkoutCtxt.customerId);

      if (!order) {
        setErrorMsg("An error has occurred while creating an order. Please try again later");
        throw new Error("An error has occurred. Please try again later")
      };

      TiktokPixel.track(
        'PlaceAnOrder',
        {
          content_id: cart.getProductIDs(),
          content_name: cart.getProductNames(products),
          quantity: cart.getItemCount(),
          content_type: 'product',
          value: checkoutCtxt.order.data.total,
          currency: 'USD'
        }
      );

      if (Object.keys(pendingOrderData).length > 0) {
        // Make deep copy of pending order
        let updatedOrder = new OrderObj(JSON.parse(JSON.stringify(pendingOrderData)));
        if (updatedOrder.ageInSeconds() < 3600) {
          updatedOrder.updateData(order.data);
          if (cartCoupon && values.shipping_method) {
            const shippingMethod = ShippingMethodObj.getById(
              shippingMethods,
              values.shipping_method
            );

            if (shippingMethod) {
              addCouponToOrder(updatedOrder, parseFloat(shippingMethod.data.cost));
            }
          } else {
            updatedOrder.removePointsFromMeta();
            updatedOrder.removeSmartCouponFromMeta();
          }

          if (cart.hasProductWithCategory('gift-card', products)) {
            if (values.send_gift_card) {
              updatedOrder.addGiftCardMeta(values.gift_card_email,
                values.gift_card_msg, true);
            } else {
              updatedOrder.addGiftCardMeta(values.email);
            }
          } else {
            updatedOrder.removeGiftCardMeta();
          }

          return OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id, updatedOrder.data);
        } else {
          // Pending order is over an hour old. Mark it canceled
          try {
            OrdersAPI.updateOrder(checkoutCtxt.token, pendingOrderData.id,
              { status: 'canceled' });
          } catch (e) {
            setErrorMsg(`Error while canceling order.`)
            OrdersAPI.createOrderNote(checkoutCtxt.token, pendingOrderData.id, `Error while canceling order (Pending order is over an hour old): ${e}`);
          }
          dispatch(setPendingOrder({}));
        }
      }
      console.log("before create", order.data);
      return OrdersAPI.createOrder(checkoutCtxt.token, order.data);
    }).then((orderData) => {
      console.log("after create", orderData);
      if ('code' in orderData && 'message' in orderData) {
        let errorMessage = orderData.message;

        const isHtml = /<\/?[a-z][\s\S]*>/i.test(errorMessage);

        if (isHtml) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = errorMessage;
          errorMessage = tempDiv.textContent || tempDiv.innerText;
        }
        setErrorMsg(errorMessage)
        if (orderData.id) {
          OrdersAPI.createOrderNote(checkoutCtxt.token, orderData.id, orderData.message);
        }
        throw new Error(errorMessage);
      }
      if (!('id' in orderData)) {
        setErrorMsg('An error has occurred while creating an order id. Please try again later.')
        throw new Error('An error has occurred. Please try again later.');
      }

      dispatch(setPendingOrder(orderData));
      // Deep copy order and add to context
      checkoutCtxt.order = new OrderObj(JSON.parse(JSON.stringify(orderData)));

      if (totalValue && parseFloat(totalValue.replace('$', '')) > 0) {
        // Complete credit card payment
        if (isSavedPayment === true) {
          return CheckoutUtils.processAuthNetSavedPayment(checkoutCtxt, getSavedCardData.user_cim);
        } else {
          if (checkoutCtxt.order.data.payment_method === "stripe") {
            let cardElement;
            if (!elements || !stripe ||
              !(cardElement = elements.getElement(CardNumberElement))) {
              console.error("Error while getting stripe card element.");
              setErrorMsg('A payment processing error was encountered (while getting stripe card element). Please try again later.');
              OrdersAPI.createOrderNote(checkoutCtxt.token, checkoutCtxt.order.data.id, "Error while getting stripe card element.");
              throw new Error(
                'A payment processing error was encountered. Please try again later.');
            }
            return CheckoutUtils.processStripePayment(stripe, cardElement, checkoutCtxt);
          } else if (checkoutCtxt.order.data.payment_method === "authorize.net") {
            if (parseFloat(checkoutCtxt.order.data.total) > .1) {
              return CheckoutUtils.processAuthNetPayment(values, checkoutCtxt, (values, checkoutCtxt) => {
                handleAuthSavePayment(values, checkoutCtxt);
              });
            }
          } else {
            console.error("Unknown payment method: ", checkoutCtxt.order.data.payment_method);
            setErrorMsg('A payment processing error was encountered (Unknown payment method). Please try again later.');
            OrdersAPI.createOrderNote(checkoutCtxt.token, checkoutCtxt.order.data.id, `Unknown payment method: ${checkoutCtxt.order.data.payment_method}`);
            throw new Error(
              'A payment processing error was encountered. Please try again later.');
          }
        }
      }
    }).then(async () => {
      const browserId = generateBrowserId();
      const userClickID = getParameterByName('fbclid');
      let ipAddress = '';
      try {
        const res = await axios.get("https://api.ipify.org/?format=json");
        ipAddress = res.data.ip;
      } catch (error) {
        console.error("Error fetching IP address from api.ipify", error);
      }

      // Order is complete. Track purchase with FB Pixel and GA4
      ReactPixel.track(
        'Purchase',
        {
          currency: 'USD',
          num_items: cart.getItemCount(),
          content_type: 'product',
          content_ids: cart.getProductIDs(),
          content_name: cart.getProductNames(products),
          value: checkoutCtxt.order.data.total,
          click_id: userClickID,
          browser_id: browserId,
          ip_address: ipAddress,
        }
      );
      ReactGA.event(
        'purchase',
        {
          currency: 'USD',
          transaction_id: checkoutCtxt.order.data.id,
          value: checkoutCtxt.order.data.total,
          items: cart.getItemsForGA(products)
        }
      );
      TiktokPixel.track(
        'CompletePayment',
        {
          content_id: cart.getProductIDs(),
          content_name: cart.getProductNames(products),
          quantity: cart.getItemCount(),
          content_type: 'product',
          value: checkoutCtxt.order.data.total,
          currency: 'USD'
        }
      );

      // Reddit Pixel tracking
      const email = customer.data.email ?
        customer.data.email : checkoutCtxt.order.data.billing.email ?
          checkoutCtxt.order.data.billing.email : '';
      const items = await Object.values(cart.items).map((item: any) => {
        const product = ProductObj.getById(products, item.product_id);
        return {
          id: product?.data.id,
          name: product?.data.name,
          category: product?.data.categories,
        }
      });
      (window as any).rdt('track', 'Purchase', {
        email: email,
        currency: 'USD',
        itemCount: cart.getItemCount(),
        transactionId: checkoutCtxt.order.data.id,
        value: checkoutCtxt.order.data.total,
        products: items
      });

      CheckoutUtils.maybePostReferral(customer, checkoutCtxt, lifeTimeAffiliate, affiliateVisitorID);
      dispatch(setPendingOrder({}));
      dispatch(clearCart(checkoutCtxt.token));
      dispatch(resetCustomer());
      dispatch(setPointsCoupon(null));
      dispatch(resetReferrals());

      const orderStatus = 'processing';
      let orderData: Record<string, any> = { status: orderStatus };
      if ('authNetTransId' in checkoutCtxt) {
        // Make deep copy of the meta_data
        orderData.meta_data = JSON.parse(JSON.stringify(checkoutCtxt.order.data.meta_data));
        orderData.meta_data.push({
          "key": "_authorize_net_trans_id",
          "value": checkoutCtxt.authNetTransId
        });
      }
      return OrdersAPI.updateOrder(checkoutCtxt.token, checkoutCtxt.order.data.id, orderData);
    }).then((orderData) => {
      // Add the new order to orders slice
      if (orderData) {
        checkoutCtxt.order = new OrderObj(orderData);
        dispatch(addOrder(orderData));
      }
      navigate(`/checkout/order-received/${checkoutCtxt.order.data.id}${AppURL}`);
    }).catch((e) => {
      setErrorMsg(e.message);
    }).finally(() => {
      dispatch(setOrderInProgress(false));
    });
  }

  useEffect(() => {
    setTotalValue(totalRef.current?.textContent || null);
  }, [cart]);

  const handleAuthSavePayment = (values: FormValues, checkoutCtxt: any) => {
    if (values.save_payment === true) {
      const billing = {
        first_name: values.billing_first_name,
        last_name: values.billing_last_name,
        address_1: values.billing_address_1,
        city: values.billing_city,
        state: values.billing_state,
        postcode: values.billing_postcode,
        country: "US",
        phone: values.phone,
        email: values.email,
      };
      const eMail = customer.data.email ?
        customer.data.email : checkoutCtxt.order.data.billing.email ?
          checkoutCtxt.order.data.billing.email : '';
      const keysExist = Object.keys(getSavedCardData).length > 0;
      const isCIMNotAvailable = getSavedCardData?.user_cim === "No CIM Data Available" && getSavedCardData?.status === 200;
      const ValidationMode = 'testMode';
      const expiryDateParts = values.cardExpiry.split('/');
      const formattedExpiryDate = `20${expiryDateParts[1]}-${expiryDateParts[0]}`;

      if (isCIMNotAvailable || !keysExist) {
        AuthNetAPI.createProfile(
          checkoutCtxt.token,
          formattedExpiryDate.replace(/\s/g, ''),
          values.cardNumber.replace(/\s/g, ''),
          ValidationMode
        ).then(async (result) => {
          const customerProfileId = result.customerProfileId;
          const customerPaymentProfileId = result.customerPaymentProfileIdList[0];
          const lastFourDigits = values.cardNumber.slice(-4);
          //const accountType = response.transactionResponse.accountType;
          const accountType = await handleCardType(values.cardNumber);

          const CIMKey = process.env.REACT_APP_AUTH_CIM_KEY || '';
          const userCIMData = {
            email: eMail,
            mmAuthCardProfileId: customerPaymentProfileId,
            mmAuthCardType: accountType,
            mmAuthCardExperiation: formattedExpiryDate.replace(/\s/g, ''),
            mmAuthCardLastFour: lastFourDigits,
            mmAuthCustomerId: customerProfileId,
            cimKey: CIMKey,
          };

          AuthNetAPI.updateUserCIM(userCIMData).then((updateResponse) => {
            dispatch(setSavedCardData(updateResponse));
          }).catch((error) => {
            console.error("Error updating user CIM data:", error);
          });
          AuthNetAPI.creatCustPayProfile(
            billing,
            customerPaymentProfileId,
            formattedExpiryDate.replace(/\s/g, ''),
            values.cardNumber.replace(/\s/g, ''),
            ValidationMode,
          ).catch((error) => {
            console.error("Error creating customer Payment profile:", error);
          })
        }).catch((error) => {
          console.error("Error creating customer profile:", error);
        });

      } else {
        AuthNetAPI.updateCustPayProfile(
          billing,
          getSavedCardData?.user_cim?.authorize_customer_id,
          getSavedCardData?.user_cim?.authorize_card_profile_id,
          values.cardNumber.replace(/\s/g, ''),
          formattedExpiryDate.replace(/\s/g, ''),
          ValidationMode,
        ).then((result) => {
          if (result.messages.resultCode === "Ok") {
            AuthNetAPI.getProfile(getSavedCardData.user_cim.authorize_customer_id).then((resResult) => {
              const CIMKey = process.env.REACT_APP_AUTH_CIM_KEY || '';
              const lastFourDigits = values.cardNumber.slice(-4);
              const expiryDateParts = values.cardExpiry.split('/');
              const formattedExpiryDate = `20${expiryDateParts[1]}-${expiryDateParts[0]}`;
              const CardType = resResult.profile.paymentProfiles[0].payment.creditCard.cardType;

              const userCIMData = {
                email: resResult.profile.email,
                mmAuthCardProfileId: resResult.profile.paymentProfiles[0].customerPaymentProfileId,
                mmAuthCardType: CardType,
                mmAuthCardExperiation: formattedExpiryDate.replace(/\s/g, ''),
                mmAuthCardLastFour: lastFourDigits,
                mmAuthCustomerId: resResult.profile.customerProfileId,
                cimKey: CIMKey,
              };

              AuthNetAPI.updateProfile(
                resResult.profile.merchantCustomerId,
                resResult.profile.description,
                resResult.profile.email,
                resResult.profile.customerProfileId
              ).then(() => {
                AuthNetAPI.updateUserCIM(userCIMData).then((updateCIMResponse) => {
                  dispatch(setSavedCardData(updateCIMResponse));
                }).catch((error) => {
                  console.error("Error updating user CIM data:", error);
                });
              }).catch((error) => {
                console.error("Error updating customer profile:", error);
              });
            }).catch((error) => {
              console.error("Error getting customer profile:", error);
            });
          }
        }).catch((error) => {
          console.error("Error update customer pay profile:", error);
        });
      }
    }

  }

  const handleCardType = (number: any) => {
    const types = creditCardType(number);
    if (types.length > 0) {
      return types[0].niceType;
    } else {
      return 'Unknown';
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values
      }) => (
        <Form className='checkout-form'>
          <Row>
            <Col sm={12} xl={6}>
              <BillingDetails />
              {!token &&
                <PasswordInput />}
              {!ShippingMethodObj.isEmailDelivery(values.shipping_method) &&
                <ShippingDetails />
              }
              <DeliveryNotesInput shippingMethod={values.shipping_method} />
            </Col>
            <Col sm={12} xl={6}>
              <YourOrder totalRef={totalRef} />
              {(cart.hasProductWithCategory('gift-card', products) || cart.hasProductWithCategory('mighty-bucks-gift-card', products)) ?
                <GiftCardInput /> : <DeliveryDateInput />
              }
              {/* {!ShippingMethodObj.isEmailDelivery(values.shipping_method) &&
                <DeliveryDateInput />
              } */}
              {showSavedCards &&
                totalValue && parseFloat(totalValue.replace('$', '')) > 0 &&
                <AuthSavedCards
                  useNewPayment={useNewPayment}
                  setUseNewPayment={setUseNewPayment} />}
              {!showSavedCards &&
                totalValue && parseFloat(totalValue.replace('$', '')) > 0 &&
                <CreditCardDetails />}
              {errorMsg &&
                <Alert variant="danger" className='my-3'>{errorMsg}</Alert>}
              {orderInProgress && !errorMsg &&
                <Alert variant="success" className='my-3 text-center'>
                  Thank you for your order! Please wait while we process it. &nbsp;&nbsp;
                  <Spinner
                    animation="border"
                    as="span"
                    size="sm"
                  />
                </Alert>}
              {!showSavedCards && creditCardProcessor === 'authorize.net' && <SavePaymentData />}
              {token &&
                <PaymentOptions values={values} key={JSON.stringify(values)} />}
              {stripe && elements &&
                <div className='d-grid gap-2'>
                  {orderInProgress ?
                    <Button variant='dark' className='my-3' size="lg" disabled>
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                      />
                      &nbsp;&nbsp;Placing order ...
                    </Button> :
                    <Button
                      variant='dark'
                      className='my-3'
                      size="lg"
                      type="submit"
                      disabled={cart.isEmpty() || (Boolean(token) && !customerIsLoaded)}
                    >
                      Place order
                    </Button>}
                </div>
              }
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}